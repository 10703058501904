<template>
  <div>
    <v-row>
      <v-col cols="12" md="12" class="pt-0">

          <div :class="$vuetify.breakpoint.name === 'lg' ? 'pr-12 pl-9' : $vuetify.breakpoint.name === 'sm' || $vuetify.breakpoint.name === 'xs' ? 'px-5 pt-8' : 'px-5'">
            <v-tabs
              v-model="tab"
              background-color="transparent"
              color="#d31145"
              :class="$vuetify.breakpoint.name === 'sm' || $vuetify.breakpoint.name === 'xs' ? 'hidden-arrow' : ''"
              slider-size="4">
              <v-tabs-slider color="#d31145"></v-tabs-slider>
              <v-tab
                v-for="(item, index) in menu"
                :key="index"
                class="text-capitalize">
                <span class="body-2" :class="tab === index ? 'font-weight-bold color-default' : 'text-third'">
                  {{ item.label }}
                </span>
              </v-tab>
            </v-tabs>
          </div>
        <!-- </v-app-bar> -->

        <v-sheet
          class="overflow-y-auto"
          :max-height="$vuetify.breakpoint.name === 'xs' ? 'calc(100vh - 100px)' : 'calc(100vh - 125px)'"
        >
          <v-tabs-items v-model="tab" touchless>
            <div :class="$vuetify.breakpoint.name === 'lg' ? 'px-8 mt-2' : 'px-4 mt-2'">
              <strong>{{ recap_detail.name }}</strong>
              <br>
              Daftar Outlet : 
              <v-chip v-for="(item, key) in data_store" :key="key" small class="mr-2 white--text text-center" color="#d31145">
                {{ item.store_name }}
              </v-chip>
            </div>
            <v-slide-x-transition>
              <router-view class="" />
            </v-slide-x-transition>
            <v-tab-item> </v-tab-item>
          </v-tabs-items>

        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { TokenService } from "@/service/Storage.Service";
import { get } from "@/service/Axios"
export default {
  data() {
    return {
      tab: 0,
      // recap_detail: {},
      // data_store: [],
      menu_baru: [],
      menu: [
        {
          label: "Ringkasan Penjualan",
          value: `/report-recap-sales/summary/${this.$route.params.id}`,
        },
        // {
        //   label: "Ringkasan Penjualan Refund",
        //   value: `/report-recap-sales/refund/${this.$route.params.id}`,
        // },
        // {
        //   label: "Ringkasan Penjualan Terhapus",
        //   value: `/report-recap-sales/void/${this.$route.params.id}`
        // },
        // {
        //   label: "Penjualan Per Kategori",
        //   value: `/report-recap-sales/perCategory/${this.$route.params.id}`,
        // },
        // {
        //   label: "Penjualan Per Produk",
        //   value: `/report-recap-sales/perProduct/${this.$route.params.id}`
        // },
        // {
        //   label: "Laba Produk",
        //   value: `/report-recap-sales/profitProduct/${this.$route.params.id}`,
        // },
        // {
        //   label: "Penjualan Per Tipe Pelanggan",
        //   value: `/report-recap-sales/perCustomerCategory/${this.$route.params.id}`,
        // },
        // {
        //   label: "Penjualan Per Pegawai",
        //   value: `/report-recap-sales/perEmployees/${this.$route.params.id}`,
        // },
        // {
        //   label: "Penjualan Per Tipe Penjualan",
        //   value: `/report-recap-sales/perType/${this.$route.params.id}`,
        // },
        // {
        //   label: "Penjualan Per Metode Pembayaran",
        //   value: `/report-recap-sales/typePayment/${this.$route.params.id}`,
        // },
        // {
        //   label: "Penjualan Harian",
        //   value: `/report-recap-sales/perDaily/${this.$route.params.id}`,
        // },
        {
          label: "Penjualan Bulanan",
          value: `/report-recap-sales/perMonthly/${this.$route.params.id}`,
        },
        {
          label: "Penjualan Tahunan",
          value: `/report-recap-sales/perYearly/${this.$route.params.id}`,
        },
      ],
    };
  },
  components: {},
  computed: {
    user() {
      return JSON.parse(TokenService.getUser());
    },
    recap_detail() {
      return JSON.parse(localStorage.getItem("setStoreRecapDetail"));
    },
    data_store() {
      return JSON.parse(localStorage.getItem("setStoreRecap"));
    }
  },
  watch: {
    tab: function(val) {
      if (val >= 0) {
        if (this.$route.path != this.menu[val].value) {
          this.$router.push({ path: `${this.menu[val].value}` });
        }
        // this.getDetailRecap()
        // this.getStore()
      }
    },
    $route(to, from) {
      //nothing
    },
  },
  mounted() {
    this.menu_privilage;
    let index = this.menu.findIndex(
      (item) => item.value === this.$route.path
    );
    this.tab = index;
  },
  methods: {},
};
</script>
